import React from 'react';
import Seo from '../components/Seo';

const FourOhFourPage = () => (
  <>
    <Seo noIndex />
    404
  </>
);

export default FourOhFourPage;
